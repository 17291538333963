import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "1c218a99-ce29-4b92-8cd8-8fcc48395180",
    videoLibraryId: "127239",
    streamCdnUrl: "https://vz-4fa75374-c0b.b-cdn.net",
    streamApiKey: "062cfc3a-32d2-4502-9c822e84bc97-5897-4c2e",
    storageApiPassword: "7abcd25d-12e9-49ca-b759e4952e94-f266-49d9",
    cdnUrl: "https://al-mouj-pwa.b-cdn.net",
    devFolder: "/al-mouj-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    proposalIcon: "/img/ui-theme/proposal-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
    
    // top level menu
    assetsMenuOrder: [
        'Al Mouj Muscat',
        '26 Vista Showcase',
        'Ritz Carlton Marina Showcase',
    ],
    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'proposal'
    ],
  
    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Al Mouj Demo'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/DCR4FIBh_Hnk'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed2Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Vista 3 Bed'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/5phsXhF-B'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed3Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Marina Arm A'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/D_0nXoSSLNt'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };